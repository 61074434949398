import { FC, ReactNode } from 'react';
import { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import { PublicRequiredDocumentsRequest } from 'src/apis/assignment-service/use-public-required-documents';
import { OperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import { DirectFcfsInfoTableRequest } from 'src/apis/direct-fcfs/use-direct-fcfs-info-table';
import { AuthenticatedMonolithUser } from 'src/apis/monolith/types';
import { OrganisationAssignmentsRequest } from 'src/apis/monolith/use-organisation-assignments';
import { TsoRequest } from 'src/apis/monolith/use-tso';
import { UserAssignmentRequest } from 'src/apis/monolith/use-user-assignment';
import { LsoContactsRequest } from 'src/apis/organisation-service/use-lso-contacts';
import { OptionalContactsRequest } from 'src/apis/organisation-service/use-optional-contacts';
import { PublicOperatorDetailsRequest } from 'src/apis/organisation-service/use-public-operator-details';
import { Card } from 'src/components/data-display/card';
import { CompanyRequiredDocuments } from 'src/components/domain-specifics/company-required-documents';
import { GeneralOperatorInformation } from 'src/components/domain-specifics/general-operator-information';
import { LsoContacts } from 'src/components/domain-specifics/lso-contacts';
import { OperatorContactDetails } from 'src/components/domain-specifics/operator-conatct-details';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { SpinnerContainer } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { UsePortal } from 'src/hooks/use-portal';
import { useTitle } from 'src/hooks/use-title';
import { AssignmentStatus } from 'src/pages/market-information/players/operators/details/assignment-status';
import { DirectFcFsTable } from 'src/pages/market-information/players/operators/details/direct-fcfs-table';
import { MarkDownArea } from 'src/pages/market-information/players/operators/details/markdown-area';

type Props = {
  actionPortal: UsePortal;
  tso: Successful<TsoRequest>;
  operatorDetails: Successful<PublicOperatorDetailsRequest>;
  userAssignment?: Successful<UserAssignmentRequest>;
  organisationAssignments?: Successful<OrganisationAssignmentsRequest>;
  caMaOperator?: OperatorRequest;
  infoTableRequest?: DirectFcfsInfoTableRequest;
  publicRequiredDocuments: Successful<PublicRequiredDocumentsRequest>;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  contacts?: Successful<OptionalContactsRequest>;
  lsoContacts?: LsoContactsRequest;
};

const Page: FC<Props> = ({
  actionPortal,
  tso,
  userAssignment,
  organisationAssignments,
  caMaOperator,
  infoTableRequest,
  operatorDetails,
  publicRequiredDocuments,
  assignmentInfo,
  contacts,
  lsoContacts,
}) => {
  useTitle(
    `Market Participants - ${operatorDetails.response.data.registeredAddress.companyName}`
  );

  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const organisationAssignment = organisationAssignments?.response.data.find(
    (assignment) => assignment.tso.id === tso.response.data.id
  );
  const operatorDetailsResponse = operatorDetails.response.data;

  return (
    <SpinnerContainer pending={tso.pending}>
      {monolithUser?.isShipper &&
        organisationAssignment &&
        actionPortal.renderContent(<LinkToAssignments user={monolithUser} />)}

      <Stack gap={2}>
        <Card>
          <GeneralOperatorInformation
            operatorDetails={operatorDetailsResponse}
          />

          <OperatorContactDetails
            contacts={contacts}
            assignmentInfo={assignmentInfo}
          />
        </Card>

        {operatorDetailsResponse.markdownContent && (
          <MarkDownArea
            markdownContent={operatorDetailsResponse.markdownContent}
          />
        )}

        {userAssignment && monolithUser && (
          <AssignmentStatus
            userAssignment={userAssignment}
            organisationAssignment={organisationAssignment}
            tso={tso}
            user={monolithUser}
            caMaOperator={caMaOperator}
          />
        )}

        {lsoContacts && <LsoContacts lsoContacts={lsoContacts} />}

        <CompanyRequiredDocuments
          tsoDetails={operatorDetailsResponse}
          publicRequiredDocuments={publicRequiredDocuments}
        />
        <DirectFcFsTable infoTableRequest={infoTableRequest} />
      </Stack>
    </SpinnerContainer>
  );
};

export const RegistrationDataStack: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { minTablet } = useBreakpoints();
  return (
    <>
      <Spacer />
      <Stack
        templateColumns={minTablet ? '1fr 1fr 1fr' : '1fr'}
        gap={1.5}
        alignItems="start"
      >
        {children}
      </Stack>
      <Spacer />
    </>
  );
};

const LinkToAssignments: FC<{ user: AuthenticatedMonolithUser }> = ({
  user,
}) => {
  const userIsShipperAdmin = user.role === 'SHIPPER_ADMIN';
  if (userIsShipperAdmin) {
    return (
      <div data-testid="assignment-link">
        Go to{' '}
        <Link mode="default-underlined" to="/operators/assignments/overview">
          Assignments
        </Link>{' '}
        to edit your status.
      </div>
    );
  } else {
    return (
      <div data-testid="assignment-link">
        Go to{' '}
        <Link mode="default-underlined" to="/operators/assignments/overview">
          Assignments
        </Link>{' '}
        for further details.
      </div>
    );
  }
};

export default Page;
