import { addMonths, setHours, startOfMonth } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { SortDirection } from 'src/apis/api-utilities';
import {
  MonthlyStatementsSortBy,
  MonthlyStatementStatus,
  MonthlyStatementType,
} from 'src/apis/capacity-management/types';
import { useNow } from 'src/hooks/use-now';
import { timeZone } from 'src/utils/date-time-format';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  const now = useNow();

  const start = useMemo(() => {
    const zonedNow = toZonedTime(now, timeZone);
    const start = setHours(startOfMonth(addMonths(zonedNow, -1)), 6);
    return fromZonedTime(start, timeZone).toISOString();
  }, [now]);

  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('sortDirection').default<SortDirection>('asc'))
      .add(
        pp
          .param('sortBy')
          .default<MonthlyStatementsSortBy>('SERVICE_PERIOD_START')
      )
      .add(
        pp
          .param('servicePeriodStart')
          .optionalDefault(start)
          .filter({ label: 'Service Period' })
      )
      .add(pp.param('servicePeriodEnd').filter({ label: 'Service Period' }))
      .add(
        pp
          .list('status')
          .cast<MonthlyStatementStatus>()
          .filter({ label: 'Status' })
      )
      .add(
        pp
          .list('monthlyStatementType')
          .cast<MonthlyStatementType>()
          .filter({ label: 'Monthly Statement Type' })
      )
      .add(
        pp.param('monthlyStatementId').filter({ label: 'Monthly Statement ID' })
      )
      .add(
        pp
          .boolean('onlyWithInvoiceCreated')
          .default(false)
          .filter({ label: 'Approved Monthly Statements' })
      )
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
