import { JWT } from 'aws-amplify/auth';

const supportsDevStage =
  PRISMA_CONFIG.stage === 'local' ||
  PRISMA_CONFIG.stage === 'storybook' ||
  PRISMA_CONFIG.stage === 'ent';
const supportsInternalTesting =
  supportsDevStage || PRISMA_CONFIG.stage === 'int';
const supportsExternalTesting =
  supportsInternalTesting ||
  PRISMA_CONFIG.stage === 'test' ||
  PRISMA_CONFIG.stage === 'kon';
const supportsProduction =
  supportsExternalTesting || PRISMA_CONFIG.stage === 'prod';

const accessTokenUsage = [
  {
    service: PRISMA_CONFIG.organisationService,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.iipService,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.capacityManagement,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.contractManagement,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.documentExchange,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.surrenderService,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.gasDataManagement,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.transparencyReporting,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.directFcfs,
    usesAccessToken: supportsProduction,
  },
  {
    service: PRISMA_CONFIG.remitReporting,
    usesAccessToken: supportsInternalTesting,
  },
  {
    service: PRISMA_CONFIG.aggregateEu,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.authorizationService,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.snamBidCollector,
    usesAccessToken: supportsProduction,
  },
  {
    service: PRISMA_CONFIG.networkPoints,
    usesAccessToken: supportsProduction,
  },
  {
    service: PRISMA_CONFIG.regularFcfs,
    usesAccessToken: supportsProduction,
  },
  {
    service: PRISMA_CONFIG.lngMarketing,
    usesAccessToken: false,
  },
  {
    service: PRISMA_CONFIG.monolithApiUrl,
    usesAccessToken: supportsProduction,
  },
];

export function chooseAuthenticationToken(
  tokens: {
    idTokenJwt: JWT;
    accessTokenJwt: JWT;
  } | null,
  url: string | undefined
) {
  if (!tokens) return null;
  const usesAccessToken = accessTokenUsage.find(
    (item) => item.service && url?.startsWith(item.service)
  )?.usesAccessToken;
  const token = usesAccessToken ? tokens.accessTokenJwt : tokens.idTokenJwt;
  return token.toString();
}
