import {
  Select,
  SelectItem as AriakitSelectItem,
  SelectPopover,
  SelectProps,
  SelectProvider,
  useSelectStore,
  ComboboxItem,
  useStoreState,
} from '@ariakit/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flattenDeep, uniq } from 'lodash';
import {
  FC,
  forwardRef,
  Fragment,
  ReactNode,
  RefObject,
  useEffect,
  useId,
  useRef,
} from 'react';
import {
  CheckboxOption,
  GroupedFormOption,
  normalizeGroupOptions,
} from 'src/components/form/checkbox-group';
import {
  CheckedIcon,
  MixedCheck,
  UncheckedIcon,
} from 'src/components/form/checkbox-icons';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { InnerAddon } from 'src/components/form/inner-addon';
import { FieldLabel } from 'src/components/form/label';
import { RemoveButton } from 'src/components/form/select/components/remove-button';
import { SelectToggleIcon } from 'src/components/form/select/components/select-toggle';
import { Separator } from 'src/components/form/select/components/separator';
import {
  selectOptionBaseStyle,
  SortByOptions,
} from 'src/components/form/select/utils';
import { borderWidth, placeholderStyle } from 'src/components/form/styles';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { RectReadOnly } from 'src/hooks/use-measure';
import {
  centeredIconOffset,
  Colors,
  disabledFocusStyle,
  flyoutStyles,
  focusStyle,
} from 'src/styles';
import styled, { css } from 'styled-components';

type MultiSelectProps<Value extends string = string> = {
  hideLabel?: boolean;
  stacked?: boolean;
  inline?: boolean;
  options: CheckboxOption<Value>[] | GroupedFormOption<Value>[];
  /** The "(Un)Select All" toggle is enabled by default. */
  toggleAll?: boolean;
  onChange?: (value: Value[]) => void;
  placeholder?: string;
  sortBy?: SortByOptions;
  initialOpen?: boolean;
} & FormFieldProps;

/**
 * Use this if you have a long list of options (more then ~5) or not enough screen space where the user can select multiple values.
 *
 * If you have a short list of options (e.g. 2-5) or enough screen space, consider using the [Checkbox Group](../?path=/docs/components-form-checkbox-group--docs) component.
 *
 * If you have a long list of options where you want to search, consider using the [Suggestions Input](../?path=/docs/components-form-suggestions-input--docs) component.
 */
export function MultiSelect<Value extends string = string>(
  props: MultiSelectProps<Value>
) {
  const { isStacked, minTablet } = useDefaultStacked();

  const {
    name,
    label,
    onChange,
    placeholder,
    stacked = isStacked,
    inline,
    options,
    hideLabel = false,
    disabled,
    disabledMessage,
    hint,
    info,
    sortBy = 'label',
    markAsRequired,
    initialOpen,
    toggleAll = true,
  } = props;

  const [field, showError, error, helper] = useCustomField<Value[]>(
    name,
    label
  );

  const formGroup = useFieldGroup();
  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);

  const id = useId();
  const fieldId = formGroup?.id || id;
  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  const fieldItem = (
    <FieldItem>
      <Tooltip
        content={disabled && disabledMessage ? disabledMessage : undefined}
      >
        {(targetProps) => (
          <SimpleMultiSelect
            id={fieldId}
            options={options}
            showError={showError}
            placeholder={placeholder}
            disabled={disabled}
            {...field}
            onChange={(value) => {
              helper.setValue(value);
              onChange?.(value);
            }}
            value={field.value}
            sortBy={sortBy}
            initialOpen={initialOpen}
            toggleAll={toggleAll}
            {...targetProps}
          />
        )}
      </Tooltip>

      {hint && (
        <Hint disabled={props.disabled} mode="formInput" children={hint} />
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked} inline={inline}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  ) : (
    fieldItem
  );
}

type SimpleMultiSelectProps<Value extends string = string> = {
  id?: string;
  name: string;
  showError?: boolean;
  placeholder?: string;
  options: CheckboxOption<Value>[] | GroupedFormOption<Value>[];
  disabled?: boolean;
  onChange: (value: Value[]) => void;
  value: Value[];
  sortBy?: SortByOptions;
  initialOpen?: boolean;
  toggleAll?: boolean;
};

function SimpleMultiSelect<Value extends string = string>({
  id: givenId,
  name,
  showError,
  disabled,
  value,
  onChange,
  options: unsortedOptions,
  placeholder = 'Please Select',
  sortBy = 'label',
  initialOpen,
  toggleAll,
}: SimpleMultiSelectProps<Value>) {
  const fallbackId = useId();
  const id = givenId ?? fallbackId;

  const { optionGroups, allChecked, toggleAllChecked } =
    normalizeGroupOptions<Value>(value, unsortedOptions, sortBy);

  const focusRef = useFocusManager<HTMLButtonElement>({ type: 'click' });

  const store = useSelectStore({
    value,
    setValue(value) {
      onChange(value);
    },
    defaultOpen: initialOpen,
  });
  const isOpen = useStoreState(store, 'open');
  const activeId = useStoreState(store, 'activeId');

  const selectedLabels = value
    .map((value) => {
      for (const optionGroup of optionGroups) {
        for (const option of optionGroup.options) {
          if (option.value === value) {
            return option.label;
          }
        }
      }
      throw new Error('Could not find label for value: ' + value);
    })
    .join(', ');

  const showRemoveButton = value.length > 0;

  const noOptions = optionGroups.every(
    (optionGroup) => optionGroup.options.length === 0
  );
  const isDisabled = disabled || noOptions;

  return (
    <div data-root>
      <SelectProvider store={store}>
        <InnerAddon
          right={
            <Stack flow="column" gap={0.5}>
              {showRemoveButton && (
                <Stack flow="column" gap={0.5}>
                  <RemoveButton
                    error={showError}
                    disabled={isDisabled}
                    data-testid={`${name}-remove-selection`}
                    onClick={() => {
                      onChange([]);
                    }}
                    style={{ pointerEvents: 'initial' }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </RemoveButton>
                  <Separator error={showError} disabled={isDisabled} />
                </Stack>
              )}

              <SelectToggleIcon
                showError={showError}
                disabled={isDisabled}
                isOpen={isOpen}
              />
            </Stack>
          }
        >
          {(addonBounds) => (
            <ToggleButton
              id={id}
              ref={focusRef}
              error={showError}
              disabled={isDisabled}
              data-name={name} // for focus manager
              data-testid={`${name}-togglebutton`}
              data-test-error={showError}
              data-style-is-placeholder={!selectedLabels}
              {...addonBounds}
            >
              <ToggleButtonContent>
                {selectedLabels ||
                  (noOptions ? 'No Options Available' : placeholder)}
              </ToggleButtonContent>
            </ToggleButton>
          )}
        </InnerAddon>

        <Popover
          style={{ zIndex: 1 }}
          data-testid={`${name}-options`}
          unmountOnHide
        >
          <MultiSelectOptions
            toggleAll={toggleAll}
            toggleAllChecked={toggleAllChecked}
            name={name}
            onChange={onChange}
            optionGroups={optionGroups}
            showError={showError}
            allChecked={allChecked}
            activeId={activeId}
            id={id}
            value={value}
            Item={SelectItem}
          />
        </Popover>
      </SelectProvider>
    </div>
  );
}

export function MultiSelectOptions<Value extends string = string>({
  toggleAll,
  toggleAllChecked,
  name,
  onChange,
  optionGroups,
  showError,
  allChecked,
  activeId,
  id,
  value,
  Item,
}: {
  toggleAll?: boolean;
  toggleAllChecked: boolean | 'mixed';
  name: string;
  onChange: (value: Value[]) => void;
  optionGroups: GroupedFormOption<Value>[];
  showError?: boolean;
  allChecked: boolean;
  activeId?: string | null;
  id: string;
  value: Value[];
  /**
   * Specify the item component that should be used for rendering the items. This can be
   * a regular select item or a comboxbox item for our searchable selects.
   */
  Item: typeof AriakitSelectItem | typeof ComboboxItem;
}) {
  return (
    <>
      {toggleAll && (
        <Item
          data-testid={`${name}-toggle-all-options`}
          onClick={(event) => {
            event.preventDefault(); // step out of ariakit's event handling

            if (toggleAllChecked !== true) {
              onChange(
                flattenDeep(
                  optionGroups.map((optionGroup) =>
                    optionGroup.options.map((option) => option.value)
                  )
                ) as Value[]
              );
            } else {
              onChange([]);
            }
          }}
          aria-checked={toggleAllChecked}
        >
          <ItemCheckbox
            checked={toggleAllChecked}
            showError={showError}
            label={
              <strong>{allChecked ? 'Unselect All' : 'Select All'}</strong>
            }
          />
        </Item>
      )}

      {optionGroups.map((optionGroup, index) => (
        <Fragment key={optionGroup.label}>
          {(index !== 0 || toggleAll) && <Spacer />}

          {Boolean(optionGroup.label) && (
            <GroupWithTooltips
              id={id}
              activeId={activeId}
              optionGroup={optionGroup}
              value={value}
              showError={showError}
              name={name}
              onChange={onChange}
              Item={Item}
            />
          )}

          {optionGroup.options.map((option) => (
            <ItemWithTooltips
              key={option.value}
              id={id}
              activeId={activeId}
              option={option}
              optionGroup={optionGroup}
              value={value}
              showError={showError}
              name={name}
              Item={Item}
            />
          ))}
        </Fragment>
      ))}
    </>
  );
}

const ToggleButtonContent = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ToggleButton = styled(
  forwardRef<
    HTMLButtonElement,
    SelectProps & {
      error?: boolean;
      leftBounds?: RectReadOnly;
      rightBounds?: RectReadOnly;
    }
  >(({ error, leftBounds, rightBounds, ...props }, ref) => (
    <Select {...props} ref={ref} />
  ))
)`
  appearance: none;
  /* see https://github.com/ariakit/ariakit/issues/845#issuecomment-785493623 */
  pointer-events: initial !important;
  text-align: left;
  background: white;
  /* white-space: nowrap; */
  text-overflow: clip;
  margin: 0;
  display: inline-grid;
  overflow: hidden;
  cursor: default;
  align-items: center;
  justify-content: space-between;
  /* mirror SimpleInput style: */
  width: 100%;
  border: ${borderWidth} solid ${Colors.brand};
  color: ${Colors.brand};
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: ${({ leftBounds }) =>
    leftBounds ? leftBounds.width + 'px' : '2rem'};
  padding-right: ${({ rightBounds }) =>
    rightBounds ? rightBounds.width + 'px' : '2rem'};

  &[data-style-is-placeholder='true'] {
    ${placeholderStyle}
  }

  &[aria-disabled] {
    border-color: ${Colors.inactive};
    background: ${Colors.inactiveLighter};
    cursor: not-allowed;
    color: ${Colors.inactive};

    /* &[data-style-is-placeholder='true'] {
      color: ${Colors.inactive};
    } */
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.error};
      background-color: ${Colors.errorLight};
      color: ${Colors.error};

      /* &[data-style-is-placeholder='true'] {
        color: ${Colors.error};
      } */
    `}

  :focus-visible {
    ${focusStyle};
  }
`;

const Popover = styled(SelectPopover)`
  ${flyoutStyles}
  left: 0;
  right: 0;
  min-width: 17.9rem;
  width: var(--popover-anchor-width);
  max-height: 23.5rem;
  overflow: auto;

  :focus-visible {
    /* by default ariakit keeps the popover focused and uses the
    activedescendant pattern to manage a virtualfocus for the active item.
    we handle the focus style for them manually. */
    outline: none;
  }
`;

function GroupWithTooltips<Value>({
  id,
  activeId,
  optionGroup,
  value,
  showError,
  name,
  onChange,
  Item,
}: {
  id: string;
  activeId: string | null | undefined;
  optionGroup: GroupedFormOption<string>;
  showError?: boolean;
  value: Value[];
  name: string;
  onChange: (value: Value[]) => void;
  Item: typeof AriakitSelectItem | typeof ComboboxItem;
}) {
  const childChecked = optionGroup.options.map((option) =>
    value.find((value) => value === option.value)
  );

  const allChildChecks = childChecked.every((check) => check);
  const someChildChecks = childChecked.some((check) => check);

  const toggleAllChildChecked = allChildChecks
    ? true
    : someChildChecks
      ? ('mixed' as const)
      : false;

  const infoRef = useRef<HTMLElement>(null);
  const disabledTooltipContent =
    optionGroup.disabled && optionGroup.disabledMessage;
  const groupTestId = `${name}-group-${optionGroup.label}`;
  const groupId = `${id}-${groupTestId}`;
  const active = activeId === groupId;

  return (
    <Tooltip content={disabledTooltipContent} noTabIndex controlActive={active}>
      {(disabledMessageTargetProps) => (
        <Tooltip
          content={optionGroup.info}
          positionRef={infoRef}
          noTabIndex
          controlActive={active}
        >
          {(infoTargetProps) => (
            <Item
              {...infoTargetProps}
              {...disabledMessageTargetProps}
              data-testid={groupTestId}
              id={groupId}
              disabled={optionGroup.disabled}
              accessibleWhenDisabled // make it possible to discover disabled items via keyboard
              onClick={(event) => {
                event.preventDefault(); // step out of ariakit's event handling

                if (toggleAllChildChecked !== true) {
                  onChange(
                    uniq([
                      ...value,
                      ...optionGroup.options.map(({ value }) => value),
                    ]) as Value[]
                  );
                } else {
                  onChange(
                    value.filter(
                      (value) =>
                        !optionGroup.options.some(
                          (option) => option.value === value
                        )
                    )
                  );
                }
              }}
              aria-checked={toggleAllChildChecked}
            >
              <ItemCheckbox
                checked={toggleAllChildChecked}
                showError={showError}
                disabled={optionGroup.disabled}
                label={<strong>{optionGroup.label}</strong>}
                info={optionGroup.info}
                infoRef={infoRef}
              />
            </Item>
          )}
        </Tooltip>
      )}
    </Tooltip>
  );
}

function ItemWithTooltips<Value>({
  id,
  activeId,
  option,
  optionGroup,
  value,
  showError,
  name,
  Item,
}: {
  id: string;
  activeId: string | null | undefined;
  option: CheckboxOption<string>;
  optionGroup: GroupedFormOption<string>;
  showError?: boolean;
  value: Value[];
  name: string;
  Item: typeof AriakitSelectItem | typeof ComboboxItem;
}) {
  const infoRef = useRef<HTMLElement>(null);
  const disabledTooltipContent =
    (optionGroup.disabled && optionGroup.disabledMessage) ||
    (option.disabled && option.disabledMessage);
  const itemTestId = `${name}:${option.value}`;
  const itemId = `${id}-${itemTestId}`;
  const active = activeId === itemId;
  return (
    <Tooltip content={disabledTooltipContent} noTabIndex controlActive={active}>
      {(disabledMessageTargetProps) => (
        <Tooltip
          content={option.info}
          positionRef={infoRef}
          noTabIndex
          controlActive={active}
        >
          {(infoTargetProps) => (
            <Item
              {...infoTargetProps}
              {...disabledMessageTargetProps}
              value={option.value}
              disabled={optionGroup.disabled || option.disabled}
              accessibleWhenDisabled // make it possible to discover disabled items via keyboard
              id={itemId}
              data-testid={itemTestId}
            >
              <ItemCheckbox
                checked={value.includes(option.value as Value)}
                showError={showError}
                disabled={optionGroup.disabled || option.disabled}
                label={option.label}
                info={option.info}
                infoRef={infoRef}
              />

              {option.hint && !option.hideHint && (
                <Hint
                  disabled={optionGroup.disabled || option.disabled}
                  mode="formInput"
                  children={option.hint}
                />
              )}
            </Item>
          )}
        </Tooltip>
      )}
    </Tooltip>
  );
}

export const multiSelectItemStyle = css`
  ${selectOptionBaseStyle};

  /* follow padding of checkbox groups */
  padding: 0.25rem;
  padding-left: 0.5rem;

  &[data-active-item]:not([aria-disabled]) {
    background-color: ${Colors.background};
    color: ${Colors.brand};
  }

  &[aria-disabled] {
    border-color: ${Colors.inactive};
    background: ${Colors.inactiveLighter};
    color: ${Colors.inactive};
    cursor: not-allowed;
  }

  [data-focus-visible='true'] &[data-active-item] {
    ${disabledFocusStyle};

    &:not([aria-disabled]) {
      ${focusStyle};
    }
  }
`;

const SelectItem = styled(AriakitSelectItem)`
  ${multiSelectItemStyle}
`;

const ItemCheckbox: FC<{
  checked: boolean | 'mixed';
  showError?: boolean;
  disabled?: boolean;
  label: string | ReactNode;
  info?: string | ReactNode;
  infoRef?: RefObject<HTMLElement>;
}> = ({ checked, disabled, showError, label, info, infoRef }) => {
  return (
    // same stack props as regular checkboxes and radios
    <Stack flow="column" gap={0.5} inline alignItems="baseline">
      <span
        style={{
          pointerEvents: 'none',
          position: 'relative',
          top: centeredIconOffset,
        }}
        aria-hidden
      >
        {checked === 'mixed' ? (
          <MixedCheck error={showError} disabled={disabled} />
        ) : checked ? (
          <CheckedIcon error={showError} disabled={disabled} />
        ) : (
          <UncheckedIcon error={showError} disabled={disabled} />
        )}
      </span>

      <span>
        {label}
        {!!info && (
          <>
            {' '}
            <FontAwesomeIcon
              forwardedRef={infoRef}
              icon={faInfoCircle}
              color={Colors.brand}
            />
          </>
        )}
      </span>
    </Stack>
  );
};
