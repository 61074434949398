import { setHours, startOfMonth, subMonths } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { SortDirection } from 'src/apis/api-utilities';
import { CurtailmentsSortBy } from 'src/apis/capacity-management/types';
import { useNow } from 'src/hooks/use-now';
import { ShipperSuggestion } from 'src/pages/reporting/shipper-transactions/shippers-suggestions-input';
import { timeZone } from 'src/utils/date-time-format';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  const now = useNow();

  // default is previous month
  const { start, end } = useMemo(() => {
    const zonedNow = toZonedTime(now, timeZone);

    const start = setHours(startOfMonth(subMonths(zonedNow, 1)), 6);
    const end = setHours(startOfMonth(zonedNow), 6);

    return {
      start: fromZonedTime(start, timeZone).toISOString(),
      end: fromZonedTime(end, timeZone).toISOString(),
    };
  }, [now]);

  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(
        pp
          .param('curtailmentPeriodStart')
          .optionalDefault(start)
          .filter({ label: 'Curtailment Period' })
      )
      .add(
        pp
          .param('curtailmentPeriodEnd')
          .optionalDefault(end)
          .filter({ label: 'Curtailment Period' })
      )
      .add(
        pp
          .param('sortBy')
          .default<CurtailmentsSortBy>('CURTAILMENT_PERIOD_START')
      )
      .add(pp.param('sortDirection').default<SortDirection>('desc'))
      .add(
        pp
          .list('shipperId')
          .deserialize<ShipperSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'Shipper' })
      )
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
