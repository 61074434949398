import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { preprocessAsBoolean } from 'src/components/form/zod-utilities';
import { z } from 'zod';

export const storageLocationsPageParamsSchema = z
  .object({
    sortColumn: z.string().default('name'),
    sortDirection: z.enum(['asc', 'desc']).default('asc'),
    favoritesOnly: z.preprocess(preprocessAsBoolean, z.boolean().default(true)),
    name: z.string().nullable().default(null),
    refId: z.string().nullable().default(null),
    ssoShortName: z.string().nullable().default(null),
  })
  .merge(getPaginationSchema());
