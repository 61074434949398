import {
  Combobox as AriakitCombobox,
  ComboboxProps as AriakitComboboxProps,
  ComboboxItem,
  ComboboxPopover,
  ComboboxPopoverProps,
  ComboboxProvider,
  useComboboxStore,
  useStoreState,
} from '@ariakit/react';
import { Placement } from '@floating-ui/react';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldInputProps } from 'formik';
import {
  useEffect,
  useId,
  ReactElement,
  ReactNode,
  FocusEvent,
  forwardRef,
  useLayoutEffect,
  useRef,
} from 'react';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { useFormMode } from 'src/components/form/form-mode-context';
import { InnerAddon } from 'src/components/form/inner-addon';
import { FieldLabel } from 'src/components/form/label';
import { SelectOption } from 'src/components/form/select';
import { RemoveButton } from 'src/components/form/select/components/remove-button';
import { SelectToggleIcon } from 'src/components/form/select/components/select-toggle';
import { Separator } from 'src/components/form/select/components/separator';
import {
  applySortBy,
  selectOptionBaseStyle,
  SortByOptions,
} from 'src/components/form/select/utils';
import {
  borderWidth,
  placeholderStyle,
  borderRadius,
} from 'src/components/form/styles';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { requiredOutput } from 'src/components/form/zod-utilities';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { RectReadOnly } from 'src/hooks/use-measure';
import { useOnChange } from 'src/hooks/use-on-change';
import { ParentZIndexProvider } from 'src/hooks/use-parent-z-index';
import {
  Colors,
  disabledFocusStyle,
  flyoutStyles,
  focusStyle,
} from 'src/styles';
import styled, { css } from 'styled-components';
import { z } from 'zod';

/**
 * Use the base schema if you add more fields to the option.
 */
export const searchableSingleSelectBaseSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const nullableSearchableSingleSelectSchema =
  searchableSingleSelectBaseSchema.nullable();

export const searchableSingleSelectSchema =
  nullableSearchableSingleSelectSchema.transform(
    requiredOutput({ message: 'Please select a value for {label}.' })
  );

type SearchableSelectValue<Value extends string> = SelectOption<Value> | null;

export type SearchableSingleSelectProps<Value extends string = string> = {
  stacked?: boolean;
  inline?: boolean;
  /**
   * If you pass undefined, this means that the initial list of options is still loading.
   */
  options: SelectOption<Value>[] | undefined;
  /**
   * When you use server side search (or maybe a debounced client side search) the options you
   * see don't necessarily reflect the current search value as the result is asynchronous and you could
   * have changed the search value in the meantime. This prop is used to keep track of the search value
   * that belongs to the current options.
   * (For a non-debounced client side search this _always_ equals the current search value.)
   *
   * `undefined` means there was no search yet.
   * `null` (or empty string) means we got a search result, but haven't used a search value.
   */
  searchValueForCurrentOptions: string | null | undefined;
  onChange?: (value: Value | null) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  placeholder?: string;
  /**
   * Defaults to 'manual' which makes most sense for server side search.
   */
  sortBy?: SortByOptions;
  /**
   * The search value always reflects the input value of our search field.
   */
  searchValue: string;
  setSearchValue: (value: string) => void;
  initialOpen?: boolean;
  leftAddon?: ReactElement;
  popoverProps?: (
    props: ComboboxPopoverProps<'div'>
  ) => ComboboxPopoverProps<'div'>;
  renderOptions?: (props: { children: ReactNode }) => ReactNode;
  pending: boolean;
  placement?: Placement;
} & FormFieldProps;

/**
 * Use this if you have a long list of options where you want to search within and where the user can only select one.
 *
 * If you have a short list of options (e.g. 2-5), consider using the [Radios](../?path=/docs/components-form-radios--docs) component.
 *
 * If you have a long list of options and don't need search, consider using the [Single Select](../?path=/docs/components-form-single-select--docs) component.
 */
export function SearchableSingleSelect<Value extends string = string>(
  props: SearchableSingleSelectProps<Value>
) {
  const { isStacked, minTablet } = useDefaultStacked();

  const {
    name,
    label,
    pending = false,
    placeholder = 'Type to Search',
    stacked = isStacked,
    inline,
    searchValueForCurrentOptions,
    hideLabel = false,
    options,
    searchValue,
    setSearchValue,
    disabled,
    disabledMessage,
    initialOpen,
    hint,
    info,
    leftAddon,
    onChange,
    onFocus,
    popoverProps,
    renderOptions,
    sortBy,
    markAsRequired,
    placement,
  } = props;

  const mode = useFormMode();
  const keepSearchValue = mode === 'search';

  const [field, showFieldError, fieldError, helper] =
    useCustomField<SearchableSelectValue<Value> | null>(name, label);

  // our actual `field` is a complex object (`FormOption`), but for validation errors
  // we're usually only interested in `FormOption['value']` and "prefer" those.
  const [_, showValueError, valueError] = useCustomField<Value | undefined>(
    `${name}.value`,
    label
  );

  const error = valueError ?? fieldError;
  const showError = showFieldError || showValueError;

  // we don't care about this. we just don't want unhandled `defined` validation errors to pop up for labels
  // as they aren't important
  useCustomField<string | undefined>(`${name}.label`, label);

  // in general we want to update the selected key and search value
  // whenever the field value changes, but we ignore the very first
  // field value in case there is an initial search value set (but no initial
  // value -> see next useEffect)
  useOnChange(() => {
    if (keepSearchValue) return;
    setSearchValue(field.value?.label ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, field.value?.value);

  // whenever an initial value was set, we update the search value so
  // it reflects the initial label (but only if there is no initial search value set)
  useLayoutEffect(() => {
    if (!field.value) return;
    if (searchValue) return;
    setSearchValue(field.value.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formGroup = useFieldGroup();

  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);

  const id = useId();

  const fieldId = formGroup?.id || id;

  const fieldItem = (
    <FieldItem>
      <Tooltip
        content={disabled && disabledMessage ? disabledMessage : undefined}
      >
        {(targetProps) => (
          <Combobox<Value>
            initialOpen={initialOpen}
            pending={pending}
            name={name}
            placeholder={placeholder}
            options={options}
            id={fieldId}
            label={label}
            searchValueForCurrentOptions={searchValueForCurrentOptions}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showError={showError}
            field={field}
            disabled={disabled}
            onFocus={onFocus}
            leftAddon={leftAddon}
            popoverProps={popoverProps}
            renderOptions={renderOptions}
            sortBy={sortBy}
            placement={placement}
            selectedValue={field.value?.value ?? ''}
            setSelectedValue={(value) => {
              if (!value) {
                helper.setValue(null);
                onChange?.(null);
                setSearchValue('');
                return;
              }

              const newItem = options?.find((item) => item.value === value);

              if (!newItem)
                // this should never happen
                throw new Error('The selected option could not be found.');

              helper.setValue(newItem);
              onChange?.(newItem.value);
              if (keepSearchValue) return;
              setSearchValue(newItem.label);
            }}
            {...targetProps}
          />
        )}
      </Tooltip>

      {hint && (
        <Hint disabled={props.disabled} mode="formInput" children={hint} />
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked} inline={inline}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  ) : (
    fieldItem
  );
}

type ComboboxProps<Value extends string = string> = {
  id: string;
  pending: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  showError?: boolean;
  /**
   * If you pass undefined, this means that the initial list of options is still loading.
   */
  options: SelectOption<Value>[] | undefined;
  /**
   * When you use server side search (or maybe a debounced client side search) the options you
   * see don't necessarily reflect the current search value as the result is asynchronous and you could
   * have changed the search value in the meantime. This prop is used to keep track of the search value
   * that belongs to the current options.
   * (For a non-debounced client side search this _always_ equals the current search value.)
   *
   * `undefined` means there was no search yet.
   * `null` (or empty string) means we got a search result, but haven't used a search value.
   */
  searchValueForCurrentOptions: string | null | undefined;
  placeholder: string;
  field: FieldInputProps<SearchableSelectValue<Value> | null>;
  initialOpen?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  leftAddon?: ReactElement;
  popoverProps?: (
    props: ComboboxPopoverProps<'div'>
  ) => ComboboxPopoverProps<'div'>;
  renderOptions?: (props: { children: ReactNode }) => ReactNode;
  /**
   * Defaults to 'manual' which makes most sense for server side search.
   */
  sortBy?: SortByOptions;
  selectedValue: string;
  setSelectedValue: (id: string) => void;
  placement?: Placement;
} & FormFieldProps;

function Combobox<Value extends string = string>(props: ComboboxProps<Value>) {
  const {
    name,
    searchValue,
    id,
    field,
    placeholder,
    showError,
    label,
    disabled,
    pending,
    initialOpen,
    setSearchValue,
    onFocus,
    leftAddon,
    popoverProps = (props) => props,
    renderOptions = ({ children }) => children,
    sortBy = 'manual',
    options: unsortedOptions,
    selectedValue,
    setSelectedValue,
    placement,
  } = props;

  const options = applySortBy(sortBy, unsortedOptions ?? []);

  const mode = useFormMode();
  const isForm = mode === 'regular';

  const focusRef = useFocusManager<HTMLInputElement>();

  const showRemoveButton = selectedValue && isForm;

  const store = useComboboxStore({
    selectedValue,
    setSelectedValue,
    value: searchValue,
    setValue: setSearchValue,
    defaultOpen: initialOpen,
    placement,
  });

  const isOpen = useStoreState(store, 'open');
  const activeId = useStoreState(store, 'activeId');

  const isFocused =
    document.activeElement && focusRef.current === document.activeElement;

  const zIndex = 1;

  const popoverChildren = (
    <>
      {pending ? (
        <SearchableSingleSelectContent>
          <EmptyValue label="Loading search results..." />
        </SearchableSingleSelectContent>
      ) : (
        <>
          {!unsortedOptions && (
            <SearchableSingleSelectContent>
              <EmptyValue label="Type to search..." />
            </SearchableSingleSelectContent>
          )}

          {options.map((option) => (
            <ItemWithTooltips
              key={option.value}
              activeId={activeId}
              option={option}
              selectedValue={selectedValue}
              id={id}
              showError={showError}
              name={name}
            />
          ))}

          {unsortedOptions?.length === 0 && searchValue && (
            <SearchableSingleSelectContent>
              <EmptyValue label={`Could not find ${searchValue}.`} />
            </SearchableSingleSelectContent>
          )}

          {unsortedOptions?.length === 0 && !searchValue && (
            <SearchableSingleSelectContent>
              <EmptyValue label="No options available to search for." />
            </SearchableSingleSelectContent>
          )}
        </>
      )}
    </>
  );

  return (
    <div>
      {/*
         keep hidden input for compability with regular single select,
         so you can easily switch to the component without changing test code
       */}
      <input
        type="hidden"
        data-testid={name}
        value={field.value?.value ?? ''}
      />

      <ComboboxProvider store={store}>
        <InnerAddon
          left={leftAddon}
          right={
            <Stack flow="column" gap={0.5}>
              {showRemoveButton && (
                <Stack flow="column" gap={0.5}>
                  <RemoveButton
                    error={showError}
                    disabled={disabled}
                    data-testid={`${name}-remove-selection`}
                    onClick={() => {
                      setSelectedValue('');
                    }}
                    style={{ pointerEvents: 'initial' }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </RemoveButton>
                  <Separator error={showError} disabled={disabled} />
                </Stack>
              )}

              <SelectToggleIcon
                showError={showError}
                disabled={disabled}
                isOpen={isOpen}
              />
            </Stack>
          }
        >
          {(addonBounds) => (
            <SearchableSelectInput
              id={id}
              ref={focusRef}
              error={showError}
              disabled={disabled}
              data-name={name} // for focus manager
              data-testid={`${name}Search`}
              placeholder={placeholder}
              {...addonBounds}
              style={{
                fontStyle:
                  isFocused && searchValue !== field.value?.label
                    ? 'italic'
                    : undefined,
              }}
              onBlur={(e) => {
                if (searchValue === field.value?.label) return;
                if (!field.value) return setSearchValue('');
                setSearchValue(field.value.label);
              }}
              onFocus={onFocus}
            />
          )}
        </InnerAddon>

        <ParentZIndexProvider value={zIndex}>
          <SearchableSelectPopover
            {...popoverProps({
              'aria-busy': pending,
              style: { zIndex },
              'data-testid': `${name}-options`,
              unmountOnHide: true,
            })}
          >
            {renderOptions({ children: popoverChildren })}
          </SearchableSelectPopover>
        </ParentZIndexProvider>
      </ComboboxProvider>
    </div>
  );
}

export const SearchableSelectInput = styled(
  forwardRef<
    HTMLInputElement,
    AriakitComboboxProps & {
      error?: boolean;
      leftBounds?: RectReadOnly;
      rightBounds?: RectReadOnly;
    }
  >(({ error, leftBounds, rightBounds, ...props }, ref) => (
    <AriakitCombobox {...props} ref={ref} />
  ))
)`
  display: inline-block;
  /* see https://github.com/ariakit/ariakit/issues/845#issuecomment-785493623 */
  pointer-events: initial !important;
  vertical-align: baseline;
  width: 100%;
  font-style: none;
  border: ${borderWidth} solid ${Colors.brand};
  color: ${Colors.brand};
  border-radius: ${borderRadius};
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: ${({ leftBounds }) =>
    leftBounds ? leftBounds.width + 'px' : '2rem'};
  padding-right: ${({ rightBounds }) =>
    rightBounds ? rightBounds.width + 'px' : '2rem'};
  line-height: normal; /* needed on iOS for centering the placeholder */

  ${({ placeholder }) =>
    placeholder &&
    css`
      ::placeholder {
        ${placeholderStyle};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.error};
      background-color: ${Colors.errorLight};
      color: ${Colors.error};

      ::placeholder {
        color: ${Colors.error};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${Colors.inactive};
      background: ${Colors.inactiveLighter};
      cursor: not-allowed;
      color: ${Colors.inactive};

      ::placeholder {
        color: ${Colors.inactive};
      }
    `}
`;

export const SearchableSelectPopover = styled(ComboboxPopover)`
  ${flyoutStyles}
  min-width: 17.9rem;
  width: var(--popover-anchor-width);
  max-height: 23.5rem;
  overflow: auto;
`;

export const SearchableSingleSelectContent = styled.div`
  padding: 1rem 2rem;
`;

function ItemWithTooltips<Value extends string = string>({
  id,
  activeId,
  option,
  selectedValue,
  name,
}: {
  id?: string;
  activeId: string | null | undefined;
  option: SelectOption<Value>;
  selectedValue: Value;
  showError?: boolean;
  name: string;
}) {
  const infoRef = useRef<HTMLElement>(null);
  const itemTestId = `${name}-option-${option.value}`;
  const itemId = `${id}-${itemTestId}`;

  const active = activeId === itemId;

  return (
    <Tooltip
      key={option.value}
      content={option.disabled && option.disabledMessage}
      noTabIndex
      controlActive={active}
    >
      {(disabledMessageTargetProps) => (
        <Tooltip
          content={option.info}
          positionRef={infoRef}
          noTabIndex
          controlActive={active}
        >
          {(infoTargetProps) => (
            <SearchableSingleSelectItem
              {...infoTargetProps}
              {...disabledMessageTargetProps}
              disabled={option.disabled}
              value={option.value}
              accessibleWhenDisabled // make it possible to discover disabled items via keyboard
              id={itemId}
              data-testid={itemTestId}
              // it looks like for a real combobox `aria-selected` is not recommended,
              // therefor ariakit doesn't set it. we use our own attribute here
              // for styling purposes only.
              data-style-is-selected={option.value === selectedValue}
            >
              <Stack
                flow="column"
                justifyContent="space-between"
                alignItems="baseline"
                gap={2}
              >
                <span>
                  {option.label}
                  {option.info && (
                    <>
                      {' '}
                      <FontAwesomeIcon
                        forwardedRef={infoRef}
                        icon={faInfoCircle}
                        color={
                          option.value === selectedValue
                            ? 'white'
                            : Colors.brand
                        }
                      />
                    </>
                  )}
                </span>

                {option.value === selectedValue && (
                  <FontAwesomeIcon
                    data-testid={`${name}-option-${option.value}-isSelected`}
                    icon={faCheck}
                  />
                )}
              </Stack>

              {option.hint && (
                <Hint
                  disabled={option.disabled}
                  mode={
                    option.value === selectedValue ? 'regular' : 'formInput'
                  }
                  children={option.hint}
                />
              )}
            </SearchableSingleSelectItem>
          )}
        </Tooltip>
      )}
    </Tooltip>
  );
}

export const SearchableSingleSelectItem = styled(ComboboxItem).attrs({
  focusOnHover: true,
  setValueOnClick: false,
  resetValueOnSelect: false,
  hideOnClick: false,
})`
  ${selectOptionBaseStyle};

  &[data-style-is-selected='true'] {
    background-color: ${Colors.brandLight2};
    color: white;

    &[data-is-placeholder='true'] {
      color: ${Colors.brandLight4};
    }
  }

  &[data-active-item]:not([data-style-is-selected='true'], [aria-disabled]) {
    background-color: ${Colors.background};
    color: ${Colors.brand};

    &[data-is-placeholder='true'] {
      ${placeholderStyle}
    }
  }

  &[aria-disabled] {
    border-color: ${Colors.inactive};
    background: ${Colors.inactiveLighter};
    color: ${Colors.inactive};
  }

  &[data-is-placeholder='true'] {
    ${placeholderStyle}
  }

  [data-focus-visible='true'] &[data-active-item] {
    ${disabledFocusStyle};

    &:not([aria-disabled]) {
      ${focusStyle};
    }
  }
`;
