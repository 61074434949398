import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { OperatorLogo } from 'src/components/domain-specifics/operator-logo';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { RegistrationDataStack } from 'src/pages/market-information/players/operators/details/page';
import { Country } from 'src/pages/settings/registration/components/country';

export const GeneralOperatorInformation: FC<{
  operatorDetails: PublicOperatorDetailsResponse;
}> = ({ operatorDetails }) => {
  return (
    <RegistrationDataStack>
      <Stack flow="column" justifyContent="start">
        <OperatorLogo
          src={operatorDetails.bigLogoUrl}
          name={operatorDetails.registeredAddress.companyName}
          large
        />
      </Stack>

      <p>
        <strong>{operatorDetails.registeredAddress.companyName}</strong>
        <br />
        {operatorDetails.registeredAddress.addressLine1}
        {operatorDetails.registeredAddress.addressLine2 && (
          <>
            <br />
            {operatorDetails.registeredAddress.addressLine2}
          </>
        )}
        <br />
        {operatorDetails.registeredAddress.postCode}{' '}
        {operatorDetails.registeredAddress.city}
        <br />
        <Country code={operatorDetails.registeredAddress.country} />
      </p>
      <div>
        <p>Short Name: {operatorDetails.shortName}</p>
        {operatorDetails.phone && (
          <p>
            Phone:{' '}
            <ExternalLink
              href={`tel:${operatorDetails.phone.countryCallingCode}${operatorDetails.phone.subscriber}`}
            >
              {operatorDetails.phone.countryCallingCode}{' '}
              {operatorDetails.phone.subscriber}
            </ExternalLink>
          </p>
        )}
        {operatorDetails.email && (
          <p>
            Email:{' '}
            <ExternalLink href={`mailto:${operatorDetails.email}`}>
              {operatorDetails.email}
            </ExternalLink>
          </p>
        )}
        {operatorDetails.websiteUrl && (
          <p>
            <ExternalLink href={operatorDetails.websiteUrl}>
              <FontAwesomeIcon icon={faExternalLink} /> Visit Website
            </ExternalLink>
          </p>
        )}
      </div>
    </RegistrationDataStack>
  );
};
