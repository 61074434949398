import { FC } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { GroupWrap } from 'src/components/group-wrap';
import { ActiveSection } from 'src/components/layout/header/types';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTrFlags } from 'src/hooks/use-tr-flags';

export const isReportingActive = (pathname: string, hash: string) =>
  pathname.startsWith('/reporting') ||
  (pathname.startsWith('/platform') && hash.startsWith('#/reporting'));

export const SecondaryNavReporting: FC<{
  activeSection: ActiveSection;
  newLngUsed: boolean;
}> = ({ activeSection, newLngUsed }) => {
  const { pathname, hash } = useLocation();
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const { minTablet } = useBreakpoints();
  const capacityManagement = useCapacityManagement();
  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  const trFlags = useTrFlags();
  const isTrSection = useMatch('/reporting/tr/*');
  const showFcfsBookings = pathname.startsWith('/reporting/fcfs');

  if (!activeSection.reporting) return null;

  return (
    <GroupWrap
      gap={2}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      {monolithUser?.currentlyActingWithOrganisationRole === 'SHIPPER' &&
        !!capacityManagement?._links?.myTransactionsV3 && (
          <Link mode="secondary" to="/reporting/my-transactions">
            My Transactions
          </Link>
        )}

      {(monolithUser?.isTso ||
        capacityManagement?._links?.draftedProducts?.href) && (
        <Link
          mode="secondary"
          to="/reporting/products/uploaded"
          isActive={pathname.startsWith('/reporting/products/')}
        >
          Products
        </Link>
      )}

      <Link mode="secondary" to="/reporting/standard">
        Standard Reports
      </Link>

      <Link mode="secondary" to="/reporting/auctions">
        Auctions
      </Link>

      <Link
        mode="secondary"
        to="/reporting/secondary"
        isActive={
          pathname.startsWith('/reporting/secondary') ||
          (pathname.startsWith('/platform') &&
            hash.startsWith('#/reporting/secondary'))
        }
      >
        Secondary Tradings
      </Link>

      {newLngUsed && (
        <Link mode="secondary" to="/reporting/lng">
          LNG
        </Link>
      )}

      {monolithUser?.currentlyActingWithOrganisationRole === 'TSO' &&
        !!capacityManagement?._links?.myTsoTransactionsV3 && (
          <Link mode="secondary" to="/reporting/shipper-transactions">
            Shipper Transactions
          </Link>
        )}

      {monolithUser?.currentlyActingWithOrganisationRole === 'TSO' &&
        !!(
          capacityManagement?._links?.monthlyStatements ||
          capacityManagement?._links?.monthlyStatementsWithCommodityFeeSupport
        ) && (
          <Link mode="secondary" to="/reporting/monthly-statements">
            Monthly Statements
          </Link>
        )}

      {monolithUser?.currentlyActingWithOrganisationRole === 'TSO' &&
        !!capacityManagement?._links?.balancingGroupAllocations && (
          <Link mode="secondary" to="/reporting/balancing-group-allocations">
            Balancing Group Allocations
          </Link>
        )}

      {(trFlags.userCanAcces || isTrSection) && (
        <Link mode="secondary" to="/reporting/tr">
          Transparency Reporting
        </Link>
      )}

      {remitReportingEntrypoint.response?.data._links?.[
        'find-primary-reports'
      ] && (
        <Link mode="secondary" to="/reporting/remit-reports">
          REMIT Reports
        </Link>
      )}

      {monolithUser?.isShipper && (
        <>
          <ExternalLink
            mode="secondary"
            href={`${PRISMA_CONFIG.angularUrl}/#/reporting/capacity-linking`}
          >
            Linked Capacity
          </ExternalLink>

          {showFcfsBookings && (
            <Link
              mode="secondary"
              to="/reporting/fcfs"
              isActive={
                pathname.startsWith('/reporting/fcfs') ||
                (pathname.startsWith('/platform') &&
                  hash.startsWith('#/reporting/fcfs/details'))
              }
            >
              FCFS Bookings
            </Link>
          )}

          <Link
            mode="secondary"
            to="/reporting/surrenders"
            isActive={
              pathname.startsWith('/reporting/surrenders') ||
              (pathname.startsWith('/platform') &&
                hash.startsWith('#/reporting/surrender/details'))
            }
          >
            Surrenders
          </Link>

          <ExternalLink
            mode="secondary"
            href={`${PRISMA_CONFIG.angularUrl}/#/reporting/contracts`}
          >
            Contract Positions
          </ExternalLink>
        </>
      )}
    </GroupWrap>
  );
};
