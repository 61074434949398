import { faExchange, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { AssignmentServiceRoot } from 'src/apis/assignment-service/types';
import { AuthorizationServiceRoot } from 'src/apis/authorization-service/types';
import { CapacityManagementRootResponse } from 'src/apis/capacity-management/types';
import { DirectFcfsEntrypoint } from 'src/apis/direct-fcfs/types';
import { OrganisationServiceRoot } from 'src/apis/organisation-service/types';
import { RegularFcfsRoot } from 'src/apis/regular-fcfs/types';
import { RemitReportingEntrypoint } from 'src/apis/remit-reporting/types';
import { useActionMode } from 'src/components/action-mode';
import { Button } from 'src/components/buttons-and-actions/button';
import { dropdownItemPadding } from 'src/components/buttons-and-actions/button-dropdown';
import { Link } from 'src/components/navigation/link';
import {
  EnhancedAuthenticatedMonolithUser,
  useOptionalAuthenticatedMonolithUser,
} from 'src/hooks/use-monolith-user';
import { useSafeLocalStorage } from 'src/hooks/use-storage';
import { Colors } from 'src/styles';
import { isLng } from 'src/utils/is-lng';
import { setLocationHref } from 'src/utils/location-usage';
import styled, { css } from 'styled-components';

type UserMenuSection =
  | 'personalSettings'
  | 'companySettings'
  | 'transportSettings'
  | 'shipperSettings'
  | 'storageSettings'
  | 'otherSettings';

export type UserMenuProps = {
  // treat the services as optional to be more resilient in the future
  // (e.g. assume we are authenticated with Cognito, but a service is offline)
  authorizationService: AuthorizationServiceRoot | null;
  organisationService: OrganisationServiceRoot | null;
  capacityManagementService: CapacityManagementRootResponse | null;
  regularFcfsService?: RegularFcfsRoot;
  directFcfsService?: DirectFcfsEntrypoint;
  show: Record<UserMenuSection, boolean>;
  assignmentService?: AssignmentServiceRoot | null;
  remitReportingService?: RemitReportingEntrypoint;
};

export const UserMenu: FC<UserMenuProps> = ({
  authorizationService,
  organisationService,
  capacityManagementService,
  regularFcfsService,
  directFcfsService,
  show,
  assignmentService,
  remitReportingService,
}) => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const mode = useMenuMode();

  const {
    personalSettings,
    companySettings,
    transportSettings,
    storageSettings,
    shipperSettings,
    otherSettings,
  } = useMenuSections({
    authorizationService,
    organisationService,
    capacityManagementService,
    regularFcfsService,
    directFcfsService,
    monolithUser,
    assignmentService,
    remitReportingService,
  });

  return (
    <MenuSections mode={mode}>
      {show.personalSettings && (
        <MenuSection
          mode={mode}
          section="personalSettings"
          title="Personal Settings"
          settings={personalSettings}
        />
      )}

      {show.companySettings && (
        <MenuSection
          mode={mode}
          section="companySettings"
          title="Company Settings (Admin)"
          settings={companySettings}
        />
      )}

      {show.transportSettings && (
        <MenuSection
          mode={mode}
          section="transportSettings"
          title="Transport Settings"
          settings={transportSettings}
        />
      )}

      {show.storageSettings && (
        <MenuSection
          mode={mode}
          section="storageSettings"
          title={isLng(monolithUser) ? 'LNG Settings' : 'Storage Settings'}
          settings={storageSettings}
        />
      )}

      {show.shipperSettings && (
        <MenuSection
          mode={mode}
          section="shipperSettings"
          title="Shipper Settings"
          settings={shipperSettings}
        />
      )}

      {show.otherSettings && (
        <MenuSection
          mode={mode}
          section="otherSettings"
          title={null}
          settings={otherSettings}
        />
      )}
    </MenuSections>
  );
};

type UserMenuMode = 'link-primary' | 'dropdown' | 'sidebar';

function useMenuMode(): UserMenuMode {
  const actionMode = useActionMode();
  if (!actionMode)
    throw new Error(
      '<UserMenu/> needs to be wrapped in <ActionModeProvider/>.'
    );

  if (
    actionMode.mode !== 'link-primary' &&
    actionMode.mode !== 'dropdown' &&
    actionMode.mode !== 'sidebar'
  )
    throw new Error(
      `<UserMenu/> was placed inside <ActionModeProvider value="${actionMode.mode}"/>. This mode is not supported.`
    );

  return actionMode.mode;
}

type MenuSectionsData = {
  personalSettings: ReactNode[];
  companySettings: ReactNode[];
  transportSettings: ReactNode[];
  shipperSettings: ReactNode[];
  storageSettings: ReactNode[];
  otherSettings: ReactNode[];
};

const useMenuSections = ({
  authorizationService,
  organisationService,
  capacityManagementService,
  regularFcfsService,
  directFcfsService,
  monolithUser,
  assignmentService,
  remitReportingService,
}: {
  authorizationService: AuthorizationServiceRoot | null;
  organisationService: OrganisationServiceRoot | null;
  capacityManagementService: CapacityManagementRootResponse | null;
  regularFcfsService?: RegularFcfsRoot;
  directFcfsService?: DirectFcfsEntrypoint;
  monolithUser: EnhancedAuthenticatedMonolithUser | null;
  assignmentService?: AssignmentServiceRoot | null;
  remitReportingService?: RemitReportingEntrypoint;
}): MenuSectionsData => {
  const safeLocalStorage = useSafeLocalStorage();
  const renderDirectFcfsLink =
    !!directFcfsService?._links?.['read-configuration'];
  const renderRegularFcfsLink =
    !!regularFcfsService?._links?.['operator-settings'];
  const { pathname, hash } = useLocation();

  const personalSettings: ReactNode[] = [];
  const companySettings: ReactNode[] = [];
  const shipperSettings: ReactNode[] = [];
  const transportSettings: ReactNode[] = [];
  const storageSettings: ReactNode[] = [];
  const otherSettings: ReactNode[] = [];

  personalSettings.push(<Link to="/settings/profile">Profile</Link>);

  personalSettings.push(
    <Link to="/settings/notifications">Notification Settings</Link>
  );

  if (organisationService?._links.getUserDetails) {
    companySettings.push(
      <Link
        to="/settings/organisation/users"
        data-testid="user-management-link"
      >
        User Management
      </Link>
    );
  }

  if (authorizationService?._links.permissionGroups) {
    companySettings.push(
      <Link to="/settings/permission-groups">Permission Groups</Link>
    );
  }

  if (organisationService?._links.getMyOrganisationDetails) {
    companySettings.push(
      <Link
        isActive={pathname.startsWith(
          '/settings/organisation/company-information'
        )}
        to="/settings/organisation/company-information"
      >
        Company Information
      </Link>
    );
  }

  if (capacityManagementService?._links?.settingsInvoicing) {
    companySettings.push(
      <Link
        isActive={pathname.startsWith('/settings/organisation/invoices')}
        to="/settings/organisation/invoices"
      >
        Invoice Settings
      </Link>
    );
  }

  if (organisationService?._links.contacts) {
    companySettings.push(
      <Link
        to="/settings/organisation/contacts"
        isActive={pathname.startsWith('/settings/organisation/contacts')}
      >
        Contacts
      </Link>
    );
  }

  if (
    monolithUser?.role === 'SHIPPER_ADMIN' ||
    remitReportingService?._links?.['secondary-settings-details']
  ) {
    companySettings.push(
      <Link to="/settings/premium-services">Premium Services</Link>
    );
  }

  if (monolithUser?.isShipper) {
    personalSettings.push(
      <Link
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/user-settings/general'
        }
        to="/settings/platform/#/user-settings/general"
      >
        View
      </Link>
    );
    personalSettings.push(
      <Link
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/user-settings/dashboard'
        }
        to="/settings/platform/#/user-settings/dashboard"
      >
        Dashboard
      </Link>
    );
    personalSettings.push(
      <Link
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/user-settings/network-points'
        }
        to="/settings/platform/#/user-settings/network-points"
      >
        Network Points
      </Link>
    );

    transportSettings.push(
      <Link
        isActive={
          pathname === '/settings/platform/' &&
          (hash === '#/user-settings/secondary-trading' ||
            hash === '#/user-settings/new-trader-list')
        }
        to="/settings/platform/#/user-settings/secondary-trading"
      >
        Secondary Trading
      </Link>
    );
  }

  if (monolithUser?.isTso) {
    transportSettings.push(
      <Link
        to="/settings/platform/#/operator-settings/general"
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/operator-settings/general'
        }
      >
        General
      </Link>
    );

    transportSettings.push(
      <Link to="/settings/general-transport-settings">General (New)</Link>
    );

    transportSettings.push(
      <Link
        to="/settings/platform/#/operator-settings/auctions"
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/operator-settings/auctions'
        }
      >
        Auctions
      </Link>
    );

    transportSettings.push(<Link to="/settings/auctions">Auctions (New)</Link>);

    transportSettings.push(
      <Link
        to="/settings/platform/#/operator-settings/secondary-trading"
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/operator-settings/secondary-trading'
        }
      >
        Secondary Trading
      </Link>
    );

    transportSettings.push(
      <Link
        to="/settings/platform/#/operator-settings/surrender"
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/operator-settings/surrender'
        }
      >
        Surrender
      </Link>
    );

    if (!regularFcfsService) {
      transportSettings.push(
        <Link
          to="/settings/platform/#/operator-settings/regular-fcfs"
          isActive={
            pathname === '/settings/platform/' &&
            hash === '#/operator-settings/regular-fcfs'
          }
        >
          Regular FCFS
        </Link>
      );
    }
  }

  if (renderRegularFcfsLink) {
    transportSettings.push(
      <Link to="/settings/regular-fcfs">Regular FCFS</Link>
    );
  }

  if (renderDirectFcfsLink) {
    transportSettings.push(<Link to="/settings/direct-fcfs">Direct FCFS</Link>);
  }

  if (monolithUser?.isSso) {
    companySettings.push(
      <Link
        to={
          isLng(monolithUser)
            ? '/settings/platform/#/lng-settings/premium-services'
            : '/settings/platform/#/storage-settings/premium-services'
        }
        isActive={
          pathname === '/settings/platform/' &&
          (isLng(monolithUser)
            ? hash === '#/lng-settings/premium-services'
            : hash === '#/storage-settings/premium-services')
        }
      >
        Premium Services
      </Link>
    );

    storageSettings.push(
      <Link
        to={
          isLng(monolithUser)
            ? '/settings/platform/#/lng-settings/general'
            : '/settings/platform/#/storage-settings/general'
        }
        isActive={
          pathname === '/settings/platform/' &&
          (isLng(monolithUser)
            ? hash === '#/lng-settings/general'
            : hash === '#/storage-settings/general')
        }
      >
        General
      </Link>
    );
  }

  if (assignmentService?._links.assignmentSettings) {
    shipperSettings.push(
      <Link to="/settings/assignment-settings">Assignment Settings</Link>
    );
  }
  if (monolithUser?.isTso) {
    shipperSettings.push(
      <Link
        to="/settings/platform/#/operator-settings/credit-limits"
        isActive={
          pathname === '/settings/platform/' &&
          hash === '#/operator-settings/credit-limits'
        }
      >
        Credit Limits
      </Link>
    );
  }

  if (
    monolithUser?.canSwitchToOrganisationRole === 'TSO' ||
    monolithUser?.canSwitchToOrganisationRole === 'SSO'
  ) {
    otherSettings.push(
      <Button
        onClick={() => {
          safeLocalStorage.removeItem('ENFORCE_SHIPPER_ROLE');
          setLocationHref(PRISMA_CONFIG.angularUrl); // hard reload and redirect to start page
        }}
      >
        <FontAwesomeIcon icon={faExchange} /> Switch to{' '}
        {monolithUser.canSwitchToOrganisationRole === 'SSO' ? 'SSO' : 'TSO'}{' '}
        Role
      </Button>
    );
  }

  if (monolithUser?.canSwitchToOrganisationRole === 'SHIPPER') {
    otherSettings.push(
      <Button
        onClick={() => {
          safeLocalStorage.setItem('ENFORCE_SHIPPER_ROLE', 'true');
          setLocationHref(PRISMA_CONFIG.angularUrl); // hard reload and redirect to start page
        }}
      >
        <FontAwesomeIcon icon={faExchange} /> Switch to Shipper Role
      </Button>
    );
  }

  if (
    monolithUser?.isOperator &&
    monolithUser.hasShipperRole &&
    monolithUser.hasAwaitingShipperRole
  ) {
    otherSettings.push(
      <Button disabled>Shipper Role Awaiting Activation</Button>
    );
  }

  otherSettings.push(
    <Link to="/logout">
      <FontAwesomeIcon icon={faSignOut} /> Logout
    </Link>
  );

  return {
    personalSettings,
    companySettings,
    transportSettings,
    shipperSettings,
    storageSettings,
    otherSettings,
  };
};

const sectionColor: Record<UserMenuSection, string | null> = {
  personalSettings: null,
  companySettings: Colors.brandLight5,
  transportSettings: '#ebeffb',
  shipperSettings: '#ebeffb',
  storageSettings: '#ebeffb',
  otherSettings: Colors.brandLight5,
};

const MenuSections = styled.div<{ mode: UserMenuMode }>`
  ${({ mode }) =>
    mode === 'sidebar' &&
    css`
      display: grid;
      grid-gap: 2rem;
      width: 20rem;
    `}

  ${({ mode }) =>
    mode === 'dropdown' &&
    css`
      overflow: auto; /* <- this clips border-radius */
      border-radius: 0.4rem;
      width: 22rem;
      display: grid;
      margin-top: -${dropdownItemPadding};
      margin-bottom: -${dropdownItemPadding};
    `}
`;

const MenuSectionWrapper = styled.div<{
  mode: UserMenuMode;
  section: UserMenuSection;
}>`
  background-color: ${({ mode, section }) =>
    mode === 'dropdown' && sectionColor[section]};
`;

const MenuSection: FC<{
  mode: UserMenuMode;
  section: UserMenuSection;
  settings: ReactNode[];
  title: string | null;
}> = ({ mode, section, settings, title }) => {
  if (!settings.length) return null;

  return (
    <MenuSectionWrapper mode={mode} section={section} data-testid={section}>
      {title && <MenuHeading mode={mode}>{title}</MenuHeading>}

      {settings.map((setting, index) => (
        <MenuItem key={index} mode={mode} hasGroupTitle={Boolean(title)}>
          {setting}
        </MenuItem>
      ))}
    </MenuSectionWrapper>
  );
};

const MenuHeading = styled.p<{ mode: UserMenuMode }>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;

  ${({ mode }) =>
    mode === 'dropdown' &&
    css`
      font-size: 1rem;
      color: #5f5f5f;
      margin-left: 1rem;
    `};

  ${({ mode }) =>
    mode === 'sidebar' &&
    css`
      margin-left: 1rem;
    `};

  ${({ mode }) =>
    mode === 'link-primary' &&
    css`
      font-size: 1rem;
      color: ${Colors.brandLight2};
    `};
`;

const MenuItem = styled.div<{ mode: UserMenuMode; hasGroupTitle: boolean }>`
  display: grid;
  text-align: left;

  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  ${({ mode, hasGroupTitle }) =>
    mode === 'link-primary' &&
    hasGroupTitle &&
    css`
      padding-left: 0.9rem;
    `}
  &:last-child {
    border-bottom: none;
  }
`;
